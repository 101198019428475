<template>
  <div class="YSBCmpt">
    <div>
      <!--9%场景业务-->
      <div class="content">
          <div class="graphic">
            <div style="display:flex; align-items:center; padding-bottom: 8px;border-bottom: 1px solid #f5f5f5;
          padding-top: 1rem;">
            <div class="title">
              交易金额统计
            </div>
            <div class="invoice-tabs">
                <div v-for="item in invoiceList" :key="item.Code">{{item.Name}}</div>
            </div>
            </div>
             <div class="invoice-tabs" style="margin-bottom: 8px;">
                <div style="margin: 8px 8px 0 0; font-weight: 400;" :class="sceneCode == item.Code ? 'isActive': ''" @click="selectScene(item.Code)" v-for="item in sceneList" :key="item.Code">{{item.Name}}</div>
            </div>
            <div class="time">
              <!-- <span @click="tabChange(item.id)" :class="timeActive == item.id ? 'active' : ''" v-for="item in timeList" :key="item.id">{{ item.name }}</span> -->
               <el-radio-group size="mini" v-model="radio" @change="tabChange">
                    <el-radio-button v-for="item in timeList" :key="item.id" :label="item.id">{{ item.name }}</el-radio-button>
                    
                  </el-radio-group>
            </div>
            <div class="tu">
              <LineChart class="chart" :id="'line3'" :InvoiceData="InvoiceData" />
            </div>
          </div>
      </div>
      <!--1%或3%场景业务-->
      <div class="content">
          <div class="graphic">
            <div style="display:flex; align-items:center; padding-bottom: 8px;border-bottom: 1px solid #f5f5f5;
          padding-top: 1rem;">
            <div class="title">
              交易金额统计
            </div>
            <div class="invoice-tabs">
                <div v-for="item in invoiceList1" :key="item.Code">{{item.Name}}</div>
            </div>
            </div>
             <div class="invoice-tabs" style="margin-bottom: 8px;">
                <div style="margin: 8px 8px 0 0; font-weight: 400;" :class="sceneCode1 == item.Code ? 'isActive': ''" @click="selectScene1(item.Code)" v-for="item in sceneList1" :key="item.Code">{{item.Name}}</div>
            </div>
            <div class="time">
              <!-- <span @click="tabChange(item.id)" :class="timeActive == item.id ? 'active' : ''" v-for="item in timeList" :key="item.id">{{ item.name }}</span> -->
               <el-radio-group size="mini" v-model="radio1" @change="tabChange1">
                    <el-radio-button v-for="item in timeList" :key="item.id" :label="item.id">{{ item.name }}</el-radio-button>
                    
                  </el-radio-group>
            </div>
            <div class="tu">
              <LineChart class="chart" :id="'line4'" :InvoiceData="InvoiceData1" />
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "../../../../components/chart/lineChart.vue";
// import PillarChart from "../../../../components/chart/pillarChart.vue";
import ColumnChart from "../../../../components/chart/columnChart.vue";
import {
  getSceneType,
  getInvioceReport,

} from '@/api/home/statistic'
import basicMixin from "@/mixins/basic";
export default {
  mixins: [basicMixin],
  data() {
    return {
      timeActive: 0, // 时间tab切换 9%
      timeActive1: 0, // 时间tab切换  1%或3%
      timeList: [
        {
          name: "过去一周",
          id: 0,
        },
        {
          name: "过去一个月",
          id: 1,
        },
        {
          name: "过去三个月",
          id: 2,
        },
      ],
      receiptActive: 1,
      InvoiceData: [], // 发票数据 9%
      InvoiceData1: [], // 发票数据 1%或3%
      radio: 0, // 切换日期选项 9%
      radio1: 0, // 切换日期选项 1%或3%
      invoiceList: [], // 发票类型 9%
      invoiceCode: '', // 选中的发票类型9%
      invoiceList1: [], // 发票类型1%或3%
      invoiceCode1: '', // 选中的发票类型1%或3%
      sceneList: [],// 发票类型下的业务场景 9%
      sceneCode: '',// 选中的场景类型 9%
      sceneList1: [],// 发票类型下的业务场景 1%或3%
      sceneCode1: '',// 选中的场景类型 1%或3%
    };
  },
  created() {
    //历史总数据
    this.getSceneType();
  },
  mounted() {
  },
  methods: {
    // 切换时间tab
    tabChange(id) {
      this.timeActive = id;
      this.getInvoiceData();
    },
    tabChange1(id) {
      this.timeActive1 = id;
      this.getInvoiceData1();
    },
    // 获取发票统计数据
    getInvoiceData() {
      let params = {
        dateType: this.timeActive,
        taskSceneType: this.sceneCode,
        sourceId: 1 // 运税宝
      };

      getInvioceReport(params).then((res) => {
        this.InvoiceData = res.data;
      });
    },
    // 获取发票统计数据
    getInvoiceData1() {
      let params = {
        dateType: this.timeActive1,
        taskSceneType: this.sceneCode1,
        sourceId: 1 // 运税宝
      };

      getInvioceReport(params).then((res) => {
        this.InvoiceData1 = res.data;
      });
    },
    // 选择场景
    selectScene(code) {
      this.sceneCode = code
      this.getInvoiceData()
    },
    // 选择场景
    selectScene1(code) {
      this.sceneCode1 = code
      this.getInvoiceData1()
    },
    // 获取发票场景类型
     getSceneType() {
      getSceneType().then((res) => {
        // 9% 发票类型
        this.invoiceList[0] = res.data[0];
        this.invoiceCode = res.data[0].Code
          // 1%或3% 发票类型
        this.invoiceList1[0] = res.data[1];
        this.invoiceCode = res.data[1].Code
        // 9% 场景类型
        if(res.data[0].Childs) {
          this.sceneList = res.data[0].Childs
          this.sceneCode = res.data[0].Childs[0].Code
          this.getInvoiceData();
        }
        // 1%或3%场景类型
        if(res.data[1].Childs) {
          this.sceneList1 = res.data[1].Childs
          this.sceneCode1 = res.data[1].Childs[0].Code
          this.getInvoiceData1();
        }
      });
    },
  },
  components: {
    LineChart,
    // PillarChart,
    ColumnChart
  },
};
</script>
<style lang="scss">
.YSBCmpt {
  .el-image {
    overflow: inherit !important;
  }
}
</style>
<style lang="scss" scoped>
@import '../../../../assets/style/variable.scss';
.YSBCmpt {
  .chart {
    width: 100%;
  }

  .tu {
    display: flex;
    align-items: center;
  }
  .graphic {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    border-radius: 4px;
    padding:0 10px;
    position: relative;
      .invoice-tabs {
          display: flex;
          div {
            margin-left: 16px;
            cursor: pointer;
            padding-bottom: 4px;
            font-weight: bold;
          }
          .isActive {
            color: #409EFF; 
            border-bottom: 1px solid #409EFF;
          }
        }

    .title {
       color: #333333;
          position: relative;
          text-indent: 10px;
          font-size: 14px;
          font-weight: bold;
        padding-bottom: 4px;
      &::after {
        content: "";
        position: absolute;
        top: 42%;
        left: 0px;
        transform: translateY(-50%);
        width: 3px;
        height: 14px;
        border-radius: 5px;
        background: $primary;
      }
    }
    .time {
      margin-bottom: 42px;
      display: flex;
      justify-content: center;
      font-size: 24px;
      font-weight: 400;
      .invoiceBtn {
        border-radius: 12px;
        // padding: 8px 20px;
        font-size: 24px;
        height: 50px;
        line-height: 50px;
        width: 176px;
        margin-bottom: 10px;
        cursor: pointer;
        margin-right: 56px;
      }
      span {
        display: inline-block;
        width: 98px;
        white-space: nowrap;
        border-radius: 6px;
        text-align: center;
        margin-right: 48px;
        font-size: 16px;
        background: #fff;
        height: 30px;
        cursor: pointer;
        line-height: 30px;
        color: #2081ff;
        border: 2px solid #2081ff;
      }
      .active {
        background: #2081ff;
        color: #fff;
      }
    }
  }
}
</style>